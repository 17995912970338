@import "@/src/styles/common.scss";
// common-functions

@mixin fitbtn() {
    transition: 0.3s opacity;
    cursor: pointer;
}

.hide {
    display: none !important;
}

.visible_hide {
    visibility: hidden !important;
}
// have-frame-info
.myfit-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    border: 0.02rem solid $gray_4;
    box-shadow: 0px 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
    border-radius: 0.08rem;
    padding: 0.18rem;
    margin: 0 0.15rem 0.24rem;
    .title {
        text-align: left;
        margin-bottom: 0.3rem;
        @include use-font-small();
        display: flex;
        align-items: center;
        .question {
            margin-left: 0.2rem;
            cursor: pointer;
            @include use-icon-small();
        }
    }
    .size-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.28rem;

        font-family: var(--font-montserrat);
        color: $text_2;
        .button-container {
            .size-edit-btn,
            .size-del-btn {
                font-size: 0.24rem;
                color: $main;
                width: auto;
                height: auto;
            }
            .size-del-btn {
                margin-left: 0.2rem;
            }
        }
    }
}

// have-frame-info: false
.find-myfit {
    margin: 0 auto 0.24rem;
}
// modal
.find-my-fit-dialog {
    width: 100vw;
    height: 100vh;
    .header {
        // height: 1.32rem;
        // font-size: 0.36rem;
        // border-bottom: 0.02rem solid $gray_4;
        // font-family: var(--font-montserrat);
        // color: #707070;
        // line-height: 1.5;
        // letter-spacing: 0.3pt;
        // .find-my-fit-title {
        //     width: 100%;
        //     text-align: center;
        //     font-size: inherit;
        //     font-family: var(--font-montserrat-medium);
        // }
        // .close-icon {
        //     font-size: 0.48rem;
        //     transform: translateY(-25%);
        // }
        .left-arrow {
            &.find-my-fit-arrow-left {
                font-size: 0.48rem;
                margin: 0;
                position: fixed;
                left: 0.2rem;
                top: 0.2rem;
            }
        }
        .header-right-close {
            position: absolute;
            right: 0.2rem;
            width: 0.4rem;
            top: 50%;
        }
    }

    // content
    .fm_dialog {
        padding: 0rem 0.4rem 0.4rem;
        .notice_container {
            &.notice1,
            &.notice2 {
                .notice .notice_p {
                    text-align: left;
                    @include use-font-normal($text_2);
                }
                // img
                .img-box {
                    position: relative;
                    .desc {
                        position: absolute;
                        font-size: 0.24rem;
                    }
                }
            }
            &.notice1 {
                .sub-notice {
                    font-size: 0.24rem;
                    font-family: var(--font-montserrat);
                    color: $text_2;
                    margin: 0.38rem 0 0;
                    text-align: left;
                }
                .img-box {
                    height: 1.65rem;

                    .desc {
                        &.desc1 {
                            top: 84%;
                            left: 32%;
                        }
                        &.desc2 {
                            top: 61%;
                            left: 32%;
                        }
                        &.desc3 {
                            top: 41%;
                            left: 32%;
                        }
                    }
                }
                .guide {
                    font-size: 0.28rem;
                    font-family: var(--font-montserrat);
                    color: $main;
                    text-align: center;
                    margin: 1rem 0 0;
                }
            }
            &.notice2 {
                .img-box {
                    position: relative;
                    margin-top: 0.5rem;
                    margin-bottom: 1rem;
                    .desc {
                        &.desc1 {
                            top: 88%;
                            left: 5%;
                        }

                        &.desc2 {
                            top: 24%;
                            left: 25%;
                        }
                        &.desc3 {
                            top: 0%;
                            left: 10%;
                        }
                    }
                }
            }

            .img-box {
                margin: 1.4rem 0.24rem 0;
                .img {
                    max-width: 100%;
                }
            }
        }
    }

    .input-line {
        margin: 0.4rem 0.16rem 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .input-item {
            flex: none;
            width: 2.05rem;
            @media (min-width: 375px) {
                & {
                    width: 2rem;
                }
            }
            &.red {
                input {
                    color: #ee5454;
                    border-color: #ee5454;
                }
            }
            input {
                width: 100%;
                height: 0.72rem;
                border: 0.02rem solid #707070;
                border-radius: 0;
                padding: 0 0.2rem;
                text-align: center;
                &:focus {
                    border-color: $main;
                }
            }

            .name {
                font-size: 0.28rem;
                font-family: var(--font-montserrat);
                color: $text_2;
            }
            .length-range {
                font-size: 0.24rem;
                font-family: var(--font-montserrat);
                color: $placeholderColor;
            }
        }
    }

    //submit-box
    .submit-box {
        margin: 0.53rem auto 0;
        // width: 6.87rem;
        .error-notice {
            font-size: 0.24rem;

            font-family: var(--font-montserrat);
            color: #ee5454;
            margin-bottom: 0.13rem;
        }
        .out-of-range {
            font-size: 0.24rem;

            font-family: var(--font-montserrat);
            color: #ee5454;
            margin-bottom: 0.2rem;
        }
        .error-notice,
        .out-of-range {
            &.do-not-show {
                visibility: hidden;
            }
        }
        .submit-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 0.8rem;
            background: $main;
            color: #fff;
            border-radius: 4px;
            font-size: 0.28rem;

            font-family: var(--font-montserrat);
            .fitbtn {
                @include fitbtn();
            }
        }
    }
}

.myfit-notice-modal {
    border: none;
    text-align: center;
    padding: 0;
    &.myfit-notice-modal-of-drawer {
        padding: 0 0.82rem 0.64rem;
    }
    .myfit-notice-box {
        .desc {
            font-size: 0.28rem;
            font-family: var(--font-montserrat);
            color: $text_2;
            margin-bottom: 0.48rem;
            .fitbtn {
                @include fitbtn();
                &.ok {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 3.8rem;
                    height: 0.79rem;
                    margin-top: 0.48rem;
                    background: #ffffff;
                    border: 0.02rem solid $main;
                    border-radius: 4px;
                }
                &.del {
                }
                &.cancel {
                }
            }
            .btns {
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-top: 0.4rem;
                .fitbtn {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    &.del {
                        width: 2.6rem;
                        height: 0.8rem;
                        background: #fff;
                        border: 0.02rem solid $main;
                        border-radius: 4px;
                        font-size: 14px;
                        font-family: var(--font-montserrat);
                        color: $main;
                    }
                    &.cancel {
                        width: 2.6rem;
                        height: 0.8rem;
                        background: $main;
                        border: 0.02rem solid $main;
                        border-radius: 4px;
                        font-size: 14px;
                        font-family: var(--font-montserrat);
                        color: #fff;
                    }
                }
            }
            .cross {
                position: absolute;
                width: 0.4rem;
                right: 0.29rem;
                top: 0.25rem;
                cursor: pointer;
            }
        }
        &.delete_content {
            .btns {
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }
    }
    .icon-cross-class {
        transform: none;
    }
}

.paper_container {
    padding: 0.88rem 0.82rem 0.64rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    font-size: 0.24rem;
    margin: 0;
    border: none;
    border-radius: 0;
    .title-scross-no-trans {
    }
}
